<template>
  <div class="main">
    <van-nav-bar fixed title="修改密码" left-arrow @click-left="onClickLeft" />
    <div class="inputBox">
      <van-form ref="loginForm">
        <van-field
          required
          v-model="loginForm.oldPassword"
          name="oldPassword"
          type="tel"
          label="原密码"
          placeholder="请输入原密码"
          clearabl
          autocomplete="off"
          :rules="[{ required: true, message: '请输入原密码' }]"
        />
        <van-field
          @input="handleInput"
          required
          v-model="loginForm.newPassword"
          type="password"
          name="newPassword"
          label="新密码"
          placeholder="请输入新密码"
          clearable
          autocomplete="off"
          :rules="[
            { required: true, message: '请输入新密码' },
            { validator: validNewPassword, message: '新密码长度限制5-32位' },
          ]"
        />
        <van-field
          required
          v-model="loginForm.repeatNewPassword"
          type="password"
          name="repeatNewPassword"
          label="确认密码"
          placeholder="请再次输入新密码进行确认"
          clearable
          autocomplete="off"
          :rules="[
            { required: true, message: '请再次输入新密码进行确认' },
            { validator: validSecondPassword, message: '两次输入的密码不一致' },
          ]"
        />
        <div style="margin: 30px 16px 0">
          <van-button
            :loading="submitLoading"
            class="submitBtn"
            block
            type="info"
            loading-text="请稍后..."
            @click="handleSubmit"
            >确 认</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>
<script>
import { Form, Field, Notify, NavBar } from "vant";
import { changePassword } from "@/api/index";
import { removeToken, removeRefreshToken } from "@/utils/auth";
export default {
  components: {
    vanForm: Form,
    vanField: Field,
    vanNavBar: NavBar,
  },
  data() {
    return {
      submitLoading: false,
      loginForm: {
        oldPassword: "",
        newPassword: "",
        repeatNewPassword: "",
      },
    };
  },

  created() {},

  methods: {
    // 再次确认新密码
    validSecondPassword(val) {
      if (val === "") {
        return true;
      } else {
        return val === this.loginForm.newPassword;
      }
    },

    validNewPassword(val) {
      if (val === "") {
        return true;
      } else {
        return val.length >= 5 && val.length <= 32;
      }
    },

    handleInput(val) {
      console.log("val" + val);
      this.loginForm.repeatNewPassword = val
        ? ""
        : this.loginForm.repeatNewPassword;
    },

    handleSubmit() {
      this.$refs.loginForm
        .validate()
        .then((res) => {
          this.submitLoading = true;
          changePassword(this.loginForm)
            .then((res) => {
              Notify({
                type: "success",
                message: "检测到密码已更换,请重新登录！",
              });
              removeToken();
              removeRefreshToken();
              localStorage.clear();
              this.submitLoading = false;
              this.$router.push({ path: "/login" });
            })
            .catch((err) => {
              this.submitLoading = false;
            });
        })
        .catch((err) => {});
    },

    // 点击返回
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css.scss";
.main ::v-deep .van-nav-bar .van-icon {
  color: $--font-color;
}
.main {
  width: 375px;
  height: 100%;
  overflow: hidden;
  background-color: $--bg-color;
  .inputBox {
    width: 345px;
    padding: 20px 0;
    margin: 60px auto;
    border-radius: 10px;
    overflow: hidden;
    // box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    .submitBtn {
      background-color: $--theme-color;
      border-color: $--theme-color;
    }
  }
}
</style>
