var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('van-nav-bar',{attrs:{"fixed":"","title":"修改密码","left-arrow":""},on:{"click-left":_vm.onClickLeft}}),_c('div',{staticClass:"inputBox"},[_c('van-form',{ref:"loginForm"},[_c('van-field',{attrs:{"required":"","name":"oldPassword","type":"tel","label":"原密码","placeholder":"请输入原密码","clearabl":"","autocomplete":"off","rules":[{ required: true, message: '请输入原密码' }]},model:{value:(_vm.loginForm.oldPassword),callback:function ($$v) {_vm.$set(_vm.loginForm, "oldPassword", $$v)},expression:"loginForm.oldPassword"}}),_c('van-field',{attrs:{"required":"","type":"password","name":"newPassword","label":"新密码","placeholder":"请输入新密码","clearable":"","autocomplete":"off","rules":[
          { required: true, message: '请输入新密码' },
          { validator: _vm.validNewPassword, message: '新密码长度限制5-32位' },
        ]},on:{"input":_vm.handleInput},model:{value:(_vm.loginForm.newPassword),callback:function ($$v) {_vm.$set(_vm.loginForm, "newPassword", $$v)},expression:"loginForm.newPassword"}}),_c('van-field',{attrs:{"required":"","type":"password","name":"repeatNewPassword","label":"确认密码","placeholder":"请再次输入新密码进行确认","clearable":"","autocomplete":"off","rules":[
          { required: true, message: '请再次输入新密码进行确认' },
          { validator: _vm.validSecondPassword, message: '两次输入的密码不一致' },
        ]},model:{value:(_vm.loginForm.repeatNewPassword),callback:function ($$v) {_vm.$set(_vm.loginForm, "repeatNewPassword", $$v)},expression:"loginForm.repeatNewPassword"}}),_c('div',{staticStyle:{"margin":"30px 16px 0"}},[_c('van-button',{staticClass:"submitBtn",attrs:{"loading":_vm.submitLoading,"block":"","type":"info","loading-text":"请稍后..."},on:{"click":_vm.handleSubmit}},[_vm._v("确 认")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }